.app-header {
  margin: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.background-container {
  background-image: url("/public/images/background.png");
  background-size: 100%;
  overflow: visible;
  flex-grow: 1;
}

.app-container {
  width: calc(min(100vw - 10px * 2 - max(10px, 5%) * 2, 600px));
  padding: calc(max(10px, 5%));
  margin: 0 auto;
  background-color: rgba(243, 246, 235, 0.90);
  border-radius: 50px;
}
@media (max-width: 1024px) {
  .app-container {
    border-radius: 40px;
  }
}
@media (max-width: 767px) {
  .app-container {
    border-radius: 5px;
  }
}

.calc-container {
  display: flex;
  flex-direction: column;
  min-height: 450px;
  gap: 30px;
}

.app-logo {
  width: 170px;
}
@media (max-width: 1024px) {
  .app-logo {
    width: 150px;
  }
}
@media (max-width: 767px) {
  .app-logo {
    width: 100px;
    margin-right: 5px;
  }
}

/* inputs */

input {
  width: 60px;
}

.input-wrapper {
  font-size: var(--secondary-font-size);
  margin: 10px auto;
  width: calc(100% - 25px * 2);
  padding: 15px 25px;
  background-color: var(--background-input-color);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.input-wrapper label {
  min-width: fit-content;
}

.input-subwrapper {
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  gap: 5px;
}
@media (max-width: 767px) {
  .input-subwrapper {
    flex-wrap: wrap;
  }
}

.input-subsidy-subwrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  margin: 0 auto;
  flex-grow: 1;
}

.currency-input-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-grow: 1;
}

.currency-input {
  text-align: right;
  color: var(--text-color);
  flex-grow: 1;
}

.missing-info {
  box-shadow: inset 0px 0px 0px 2px var(--accent-color);
}

select,
.subsidy-wrapper input {
  font-size: var(--secondary-font-size);
  color: var(--text-color);
  padding: 3px 12px;
  border-radius: 10px;
  border: none;
  background-color: #f2f2f2;
  appearance: none; /* Removes default styles */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
}

select.age-select {
  flex-grow: 1;
}
select.product-select {
  flex-grow: 1;
}
select.meal-select {
  flex-grow: 1;
  width: 150px;
}
select.frequency-select {
  flex-grow: 1;
  width: 150px;
}

.subsidy-wrapper {
  border: none;
  background-color: var(--background-input-color);
  border-radius: 18px;
  padding: 1px 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.nowrap {
  white-space: nowrap;
}