.spinner,
.lng-spinner {
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  border-top: 2px solid var(--text-color);
}

/* main app */
.spinner {
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* language switcher */
.lng-spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 30px;
}
@media (max-width: 767px) {
  .lng-spinner-wrapper {
    width: 20px;
  }
}

.lng-spinner {
  width: 10px;
  height: 10px;
  animation: lng-spin 1s linear infinite;
}

@keyframes lng-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
