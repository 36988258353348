.button-wrapper {
  display: flex;
  width: 100%;
  margin: calc(max(2.5%, 15px)) 0;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.back-next-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 185px;
  max-width: 45%;
  border-radius: 30px;
  background-color: var(--primary-color);
  color: var(--text-color-on-dark);
  border: 0px solid var(--primary-color);
  font-size: var(--main-font-size);
  font-weight: 400 !important;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 5px 0;
}

.back-next-button:hover {
  transition: all 0.3s ease;
  transform: scale(1.025);
}

.back-next-button-icon-right {
  margin-left: 8px;
}

.back-next-button-icon-left {
  margin-right: 8px;
}
.svg-icon {
  width: 26px;
  height: 26px;
  fill: currentColor;
}
.svg-button-icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
  padding-left: 8px;
}

.add-button, .remove-button {
  display: inline-flex;
  align-items: left;
  border-radius: 3px;
  background-color: transparent;
  color: var(--primary-color);
  border: none;
  font-size: var(--main-font-size);
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  align-items: center;
}
.add-button svg {
  padding-right: 10px;
}

.add-button:hover, .remove-button:hover {
  scale: 1.025;
}

.add-button {
  margin: 15px 20px;
}

.lng-button-wrapper {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 5px;
}
.lng-button {
  background-color: transparent;
  color: var(--text-color-soft);
  border: none;
  font-size: var(--secondary-font-size);
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: unset;
}

.lng-button:hover {
  color: var(--text-color);
}