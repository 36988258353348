@font-face {
  font-family: "InterTight";
  src: url("/public/fonts/InterTight-Regular.woff2"),
    url("/public/fonts/InterTight-Regular.woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "InterTight";
  src: url("/public/fonts/InterTight-SemiBold.woff2"),
    url("/public/fonts/InterTight-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

:root {
  --primary-color-soft: #dff0e6;
  --primary-color: #478464;
  --secondary-color: #84c5a3;
  --primary-color-soft: rgb(188, 189, 176);
  --accent-color: #ed4545;
  --background-color: rgb(243, 246, 235);
  --background-input-color: #fff;
  --text-color-soft: #4f4f49;
  --text-color: #4f4f49;
  --text-color-on-dark: #f2f6ea;
  --text-color-muted: #9a9a9a;
  --h1-font-size: 45px;
  --h2-font-size: 36px;
  --main-font-size: 20px;
  --secondary-font-size: 18px;
  --footer-font-size: 14px;
  --font-multiplier: 1;
}

@media (max-width: 767px) {
  :root {
    --h1-font-size: 35px;
    --h2-font-size: 28px;
    --main-font-size: 16px;
    --secondary-font-size: 14px;
    --footer-font-size: 13px;
  }
}

body {
  font-family: InterTight, Arial, Lucida Sans, helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--main-font-size);
  font-weight: 400;
  margin: 0;

  background-color: var(--background-color);

  color: var(--text-color);

  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

h1,
h2, h3 {
  text-align: center;
  font-weight: 600;
  margin: 0;
}

p {
  text-align: center;
}

h1 {
  color: var(--primary-color);
  font-size: var(--h1-font-size);
}

h2 {
  color: var(--secondary-color);
  font-size: var(--h2-font-size);
}
h3 {
  margin: 30px 0;
}

footer {
  margin-top: calc(max(5%, 25px));
  padding: 10px;
  text-align: center;
  font-size: var(--footer-font-size);
  color: var(--text-color-muted);
  text-shadow:
  -1px -1px 0 var(--background-color), 1px -1px 0 var(--background-color), -1px 1px 0 var(--background-color), 1px 1px 0 var(--background-color),
  -2px -2px 0 var(--background-color), 2px -2px 0 var(--background-color), -2px 2px 0 var(--background-color), 2px 2px 0 var(--background-color);
}
