table {
  border-collapse: collapse;
  width: 90%;
  margin: 0 auto;
  font-size: var(--main-font-size);
  text-align: center;
}

table tr:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}

th,
td {
  padding: 10px;
  font-weight: 400;
}

thead {
  color: var(--primary-color);
}

/* Set column widths */
table th:nth-child(1), table td:nth-child(1) {
  width: 50%;
}

table th:nth-child(2), table td:nth-child(2) {
  width: 50%;
}

table th:nth-child(3), table td:nth-child(3) {
  width: 15%;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.placeholder {
  width: 90%;
  height: 90%;
  background-color: rgba(250, 250, 250, 0.9);
  border-radius: 20px;
  animation: pulse 1s ease-in-out infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.economic-subsidy-notice {
  padding: 10px 15px;
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;
}

.disclaimer {
  font-size: var(--footer-font-size);
  color: var(--text-color-soft);
  margin-top: 20px;
  text-align: center;
}
.list-content {
  margin-top: 50px;
  background-color: var(--background-input-color);
  padding: 20px 0;
  border-radius: 20px;
}

#print .button-wrapper {
  margin-top: 50px !important;
}
#print a {
  width: 185px;
}
#print a .back-next-button {
  min-width: 100%;
}

@media print {
  @page {
    margin-top: 2cm;
    margin-bottom: 2cm;
    margin-left: 2cm;
    margin-right: 2cm;
  }

  :root {
    --font-multiplier: 0.7;
  }
  .button-wrapper *, .lng-button-wrapper * {
    display: none;
  }
  .app-header {
    justify-content: center;
  }  
  .app-container {
    box-shadow: none;
    padding: 0;
  }
  body {
    padding: 0;
    margin: 0;
    background: none;
  }
}